<template>
  <div>
    <b-container fluid class="shadow mb-5">
      <h1 class="text-center my-4" style="min-height: 1.2em">
        <a
          :href="'https://www.google.com/search?q=' + keyword.text"
          class="text-decoration-none text-muted"
          >Keyword: {{ keyword.text }}</a
        >
      </h1>
      <h1 class="text-center my-4" style="min-height: 1.2em">
        <a :href="keyword.url" class="text-decoration-none text-muted"
          >URL: {{ keyword.url }}</a
        >
      </h1>
      <b-row class="mb-5">
        <b-col class="mb-1">
          <b-form-input
            class="mb-3"
            id="keyword-string"
            style="box-shadow: none"
            v-model="keyword.text"
            placeholder="Enter keyword ..."
            size="sm"
            squared
            trim
            description="keyword"
            autofocus
            type="text"
            @keydown.enter.native="competitorsMissing()"
          ></b-form-input>
          <b-form-input
            class="mb-3"
            id="url-string"
            style="box-shadow: none"
            v-model="keyword.url"
            placeholder="Enter url ..."
            size="sm"
            squared
            trim
            description="url"
            autofocus
            type="text"
            @keydown.enter.native="competitorsMissing()"
          ></b-form-input>
          <b-button-group>
            <b-dropdown
              variant="dark"
              size="sm"
              squared
              :text="
                'location: ' +
                keyword.location.name +
                ' ' +
                '(' +
                keyword.location.code +
                ')'
              "
              id="keyword-location"
              name="keyword.location"
              v-model="keyword.location"
            >
              <b-dropdown-item-button
                v-for="location in locations"
                :key="location.name"
                :value="location.country_iso_code"
                squared
                @click="keyword.location = location"
              >
                {{
                  location.name + " " + "(" + location.country_iso_code + ")"
                }}
              </b-dropdown-item-button>
            </b-dropdown>

            <b-dropdown
              variant="dark"
              size="sm"
              squared
              v-model="keyword.language_code"
              :text="'language: ' + keyword.language_code"
            >
              <b-dropdown-item-button
                v-for="language_code in languages"
                :key="language_code"
                :value="language_code"
                squared
                @click="keyword.language_code = language_code"
              >
                {{ language_code }}
              </b-dropdown-item-button>
            </b-dropdown>

            <b-dropdown
              variant="dark"
              size="sm"
              squared
              v-model="keyword.device"
              :text="'device: ' + keyword.device"
            >
              <b-dropdown-item-button
                v-for="device in devices"
                :key="device"
                :value="device"
                squared
                @click="keyword.device = device"
              >
                {{ device }}
              </b-dropdown-item-button>
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="pb-3">
        <b-col cols="2">
          <b-form-checkbox
            id="checkbox-exclude-own-url"
            v-model="keyword.excludeOwnUrl"
            name="checkbox-exclude-own-url"
            value="accepted"
            unchecked-value="not_accepted"
            squared
          >
            Exclude Own Url
          </b-form-checkbox>
        </b-col>
        <b-col>
          <FilterInput :filterInput="filterInput" />
        </b-col>
        <!-- <b-col v-if="!isBusy" class="mr-1">
          {{ "Data from: " + dataFrom }}
        </b-col> -->
      </b-row>
      <b-row
        ><b-button
          :pressed="false"
          style="box-shadow: none"
          active-class="active"
          size="sm"
          squared
          variant="danger"
          @click="competitorsMissing()"
          >Analyze</b-button
        ></b-row
      >
      <!-- spinner -->
      <div class="text-center mb-5" v-if="isBusy && spinner">
        <b-row type="grow" class="justify-content-md-center my-5 pb-5">
          <b-spinner
            variant="dark"
            style="width: 25rem; height: 25rem"
          ></b-spinner>
        </b-row>
        <span class="sr-only">Analyzing "{{ keyword.text }}"</span>
      </div>
    </b-container>

    <b-container fluid v-if="!isBusy" class="shadow">
      <b-row class="mb-5 shadow">
        <b-col>
          <h2 class="text-center m-3">Competitors</h2>
          <table class="table striped small hover responsive">
            <thead>
              <tr>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  Position
                </th>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  Title
                </th>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  URL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataforseoItems" :key="item.id">
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  {{ item.rank_group }}
                </td>
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  {{ item.title }}
                </td>
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  <a :href="item.url">{{ item.url }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-row v-if="!isBusy" class="mb-5 shadow">
        <h2 class="text-center my-4">Taxonomy</h2>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingConceptsOptions"
            :series="seriesMissingConcepts"
            :type.sync="chartMissingConceptsOptions.chart.type"
            id="chart-missing-concepts"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingKeywordsOptions"
            :series="seriesMissingKeywords"
            :type.sync="chartMissingKeywordsOptions.chart.type"
            id="chart-missing-keywords"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingCategoriesOptions"
            :series="seriesMissingCategories"
            :type.sync="chartMissingCategoriesOptions.chart.type"
            id="chart-missing-categories"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingEntitiesOptions"
            :series="seriesMissingEntities"
            :type.sync="chartMissingEntitiesOptions.chart.type"
            id="chart-missing-entities"
            fluid
          />
        </b-col>
      </b-row>
      <!-- <b-row v-if="!isBusy" class="mb-5 shadow">
        <h2 class="text-center my-4">Report</h2>
        <h4 class="text-center my-4">Missing Concepts</h4>
        <p v-for="missingConcept in missingConcepts" :key="missingConcept">
          {{ missingConcept }}
        </p>
        <h4 class="text-center my-4">Missing Categories</h4>
        <p v-for="missingCategory in missingCategories" :key="missingCategory">
          {{ missingCategory }}
        </p>
        <h4 class="text-center my-4">Missing Entities</h4>
        <p v-for="missingEntity in missingEntities" :key="missingEntity">
          {{ missingEntity }}
        </p>
        <h4 class="text-center my-4">Missing Keywords</h4>
        <p v-for="missingKeyword in missingKeywords" :key="missingKeyword">
          {{ missingKeyword }}
        </p>
        {{ missingConcepts }}
        {{ missingCategories }}
        {{ missingEntities }}
        {{ missingKeywords }}
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import { TaxonomyService } from "../services/taxonomy/taxonomy.service";
// import Search from "../components/Search.vue";
// import Sort from "../components/Sort.vue";
import FilterInput from "../components/FilterInput.vue";
import Chart from "../components/Chart.vue";

export default {
  name: "Taxonomy",
  components: {
    FilterInput,
    Chart,
  },
  data() {
    return {
      isBusy: true,
      spinner: false,
      dataFrom: "",
      keyword: {
        // text: "authorization",
        // url: "https://www.kaspersky.com/blog/identification-authentication-authorization-difference/37143/",
        text: "",
        url: "",
        language_code: "de",
        device: "mobile",
        // location: ["2276", "Germany", "", "DE", "Country"],
        location: {
          code: 2276,
          name: "Germany",
          code_parent: "",
          country_iso_code: "DE",
          type: "Country",
        },

        forceUpdate: false,
        excludeOwnUrl: false,
      },
      languages: [
        "en",
        "de",
        "ru",
        // "fa",
        // "nl",
        // "fr",
        // "id",
        // "it",
        // // 'ja',
        // "no",
        // "pt",
        // "es",
        // "sv",
      ],
      devices: ["desktop", "mobile"],
      locations: [],

      filterInput: {
        value: 5,
        min: 1,
        max: 20,
        step: 1,
        name: "Google top results",
      },

      //dataforseo
      dataforseoItems: [],

      // Missing concepts
      seriesMissingConcepts: [],
      chartMissingConceptsOptions: {
        chart: {
          id: "Concepts",
          type: "bar",
          stacked: true,
          //   height: 1440,
          // stackType: "100%",
          // stackType: "normal",
        },
        // colors: ["#0000FF", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
            // borderRadius: 4,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
              //   show: true,
            },
          },
        },

        // title: {
        //   text: "Concepts",
        // },
      },

      // Missing keywords
      seriesMissingKeywords: [],
      chartMissingKeywordsOptions: {
        chart: {
          id: "Keywords",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "keyword",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      // Missing categories
      seriesMissingCategories: [],
      chartMissingCategoriesOptions: {
        chart: {
          id: "Categories",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "category",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      // Missing entities
      seriesMissingEntities: [],
      chartMissingEntitiesOptions: {
        chart: {
          id: "Entities",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "entity",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      missingConcepts: [],
      missingCategories: [],
      missingEntities: [],
      missingKeywords: [],
    };
  },

  // watch: {
  // },

  computed: {
    // sortOptions() {
    //   return this.fields
    //     .filter((f) => f.sortable)
    //     .map((f) => {
    //       return { text: f.label, value: f.key };
    //     });
    // },
  },

  async created() {
    const locations = await TaxonomyService.getLocations();
    this.locations = locations.data;
  },

  // async created() {
  //   await this.getChartConceptsDataCompetitors();
  //   // this.locations = await this.getLocations();
  // },

  // async mounted() {
  //   await this.getChartConceptsDataCompetitors();
  //   // await this.getMoreItems();
  // },

  methods: {
    async competitorsMissing() {
      if (this.keyword.text 
      // && this.keyword.url
      ) {
        this.isBusy = true;
        this.spinner = true;

        const { data: data } = await TaxonomyService.competitorsMissing({
          keyword: this.keyword.text.toLowerCase(),
          url: this.keyword.url.toLowerCase(),
          language_code: this.keyword.language_code,
          location_code: this.keyword.location.code.toString(),
          device: this.keyword.device,
          serp_top_n: this.filterInput.value,
          // serp_top_n: 2,
          forceUpdate: this.keyword.forceUpdate,
          excludeOwnUrl: this.keyword.excludeOwnUrl,
        });

        this.dataforseoItems = data.competitorsData;

        this.getChartMissingConceptsData(data.competitorsData, data.urlData);
        this.getChartMissingKeywordsData(data.competitorsData, data.urlData);
        this.getChartMissingCategoriesData(data.competitorsData, data.urlData);
        this.getChartMissingEntitiesData(data.competitorsData, data.urlData);

        // this.missingConcepts =
        //   this.chartCompetitorsConceptsOptions.xaxis.categories.filter((x) => {
        //     return !this.chartURLConceptsOptions.xaxis.categories.includes(
        //       x.substring(0, x.indexOf("|"))
        //     );
        //   });

        // this.missingCategories =
        //   this.chartCompetitorsCategoriesOptions.xaxis.categories.filter(
        //     (x) => {
        //       return !this.chartURLCategoriesOptions.xaxis.categories.includes(
        //         x
        //       );
        //     }
        //   );
        // this.missingEntities =
        //   this.chartCompetitorsEntitiesOptions.xaxis.categories.filter((x) => {
        //     return !this.chartURLEntitiesOptions.xaxis.categories.includes(x);
        //   });
        // this.missingKeywords =
        //   this.chartCompetitorsKeywordsOptions.xaxis.categories.filter((x) => {
        //     return !this.chartURLKeywordsOptions.xaxis.categories.includes(x);
        //   });

        this.keyword.forceUpdate = false;
        this.isBusy = false;
        this.spinner = false;
        return;
      }
    },

    getChartMissingEntitiesData(competitorsData, urlData) {
      const competitorsEntitiesAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorEntities = (({ entities }) => ({ entities }))(
          competitorData
        ).entities;
        competitorEntities.forEach((entity) => {
          competitorsEntitiesAll.push(entity);
        });
      });

      const competitorsEntitiesFiltered = [];
      competitorsEntitiesAll.forEach((entity) => {
        const entityData = {
          entity: entity.text,
          type: entity.type,
          relevance_sum: entity.relevance,
          count: +entity.count,
          serps: [entity.url],
        };
        competitorsEntitiesAll.forEach((entity2) => {
          if (entity.text == entity2.text && entity.url != entity2.url) {
            entityData.relevance_sum += +entity2.relevance;
            // entityData.count++;
            entityData.count += +entity2.count;
            entityData.serps.push(entity2.url);
          }
        });
        const data = {
          entity: entityData.entity,
          type: entityData.type,
          count: entityData.count,
          relevance_avg: Number(
            +entityData.relevance_sum / entityData.serps.length
          ).toFixed(3),
          serps: entityData.serps,
        };
        competitorsEntitiesFiltered.push(data);
      });


      const competitorsEntities = competitorsEntitiesFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["entity", "type"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_sum_avg < b.relevance_sum_avg
            ? 1
            : b.relevance_sum_avg < a.relevance_sum_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const counts = [];
      // const count_sums = [];
      // const count_sum_avgs = [];
      // const relevance_sum_avgs = [];

      // for (let i = 0; i < entities.slice(0, 20).length; i++) {
      //   const item = entities[i];
      //   // counts.push(item.count);
      //   count_sums.push(item.count_sum);
      //   count_sum_avgs.push(item.count_sum_avg);
      //   relevance_sum_avgs.push(item.relevance_sum_avg);
      // }

      const relevance_avgs = [];
      for (let i = 0; i < competitorsEntities.slice(0, 20).length; i++) {
        const item = competitorsEntities[i];
        chartOptions.push(item.entity + " | " + item.type);
        if (urlData.entities.some((e) => e.text === item.entity)) {
          counts.push({
            x: item.entity,
            y: item.count,
            // fillColor: "#5555FF",
          });
          relevance_avgs.push({
            x: item.entity,
            y: item.relevance_avg,
            // fillColor: "#0000FF",
          });
        } else {
          counts.push({
            x: item.entity,
            y: item.count,
            fillColor: "#FF5555",
          });
          relevance_avgs.push({
            x: item.entity,
            y: item.relevance_avg,
            fillColor: "#FF0000",
          });
        }
      }
      this.chartMissingEntitiesOptions = {
        ...this.chartMissingEntitiesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                // fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              // fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesMissingEntities = [
        { name: "Count", data: counts },
        // { name: "count_sum", data: count_sums },
        // { name: "count_sum_avg", data: count_sum_avgs },
        { name: "Relevance avg", data: relevance_avgs },
      ];
    },

    getChartMissingCategoriesData(competitorsData, urlData) {
      const competitorsCategoriesAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorCategories = (({ categories }) => ({ categories }))(
          competitorData
        ).categories;
        competitorCategories.forEach((category) => {
          competitorsCategoriesAll.push(category);
        });
      });
      const competitorsCategoriesFiltered = [];

      competitorsCategoriesAll.forEach((category) => {
        const categoryData = {
          category: category.label,
          count: 1,
          score_sum: category.score,
          serps: [category.url],
        };

        competitorsCategoriesAll.forEach((category2) => {
          if (
            category.label == category2.label &&
            category.url != category2.url
          ) {
            categoryData.score_sum += +category2.score;
            categoryData.count++;
            categoryData.serps.push(category2.url);
          }
        });
        const data = {
          category: categoryData.category,
          count: categoryData.count,
          score_avg: Number(
            +categoryData.score_sum / +categoryData.count
          ).toFixed(3),
          serps: categoryData.serps,
        };
        competitorsCategoriesFiltered.push(data);
      });

      const competitorsCategories = competitorsCategoriesFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["category", "score_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.category < b.category ? 1 : b.category < a.category ? -1 : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const score_avgs = [];
      const counts = [];
      for (let i = 0; i < competitorsCategories.slice(0, 100).length; i++) {
        const item = competitorsCategories[i];
        chartOptions.push(item.category);
        if (urlData.categories.some((e) => e.label === item.category)) {
          score_avgs.push({
            x: item.category,
            y: item.score_avg,
            // fillColor: "#5555FF",
          });
          counts.push({
            x: item.category,
            y: item.count,
            // fillColor: "#5555FF",
          });
        } else {
          score_avgs.push({
            x: item.category,
            y: item.score_avg,
            fillColor: "#FF5555",
          });
          counts.push({
            x: item.category,
            y: item.count,
            fillColor: "#FF5555",
          });
        }
      }
      this.chartMissingCategoriesOptions = {
        ...this.chartMissingCategoriesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "20%",
              borderRadius: 4,
              horizontal: true,
              // columnWidth: "20%"
              barWidth: "20%",
            },
          },
        },
      };
      this.seriesMissingCategories = [
        // { name: "score_avg", data: score_avgs },
        { name: "count", data: counts },
      ];
    },

    getChartMissingKeywordsData(competitorsData, urlData) {
      const competitorsKeywordsAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorKeywords = (({ keywords }) => ({ keywords }))(
          competitorData
        ).keywords;
        competitorKeywords.forEach((keyword) => {
          competitorsKeywordsAll.push(keyword);
        });
      });
      const competitorsKeywordsFiltered = [];

      competitorsKeywordsAll.forEach((keyword) => {
        const keywordData = {
          keyword: keyword.text,
          relevance_sum: keyword.relevance,
          count: +keyword.count,
          serps: [keyword.url],
        };
        competitorsKeywordsAll.forEach((keyword2) => {
          if (keyword.text == keyword2.text && keyword.url != keyword2.url) {
            keywordData.relevance_sum += +keyword2.relevance;
            // keywordData.count++;
            keywordData.count += +keyword2.count;
            keywordData.serps.push(keyword2.url);
          }
        });
        const data = {
          keyword: keywordData.keyword,
          count: keywordData.count,
          relevance_avg: Number(
            +keywordData.relevance_sum / keywordData.serps.length
          ).toFixed(3),
          serps: keywordData.serps,
        };
        competitorsKeywordsFiltered.push(data);
      });
      const competitorsKeywords = competitorsKeywordsFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["keyword", "count", "relevance_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_avg < b.relevance_avg
            ? 1
            : b.relevance_avg < a.relevance_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));
      const chartOptions = [];
      const counts = [];
      const relevance_avgs = [];
      for (let i = 0; i < competitorsKeywords.slice(0, 20).length; i++) {
        const item = competitorsKeywords[i];
        chartOptions.push(item.keyword);
        if (urlData.keywords.some((e) => e.text === item.keyword)) {
          counts.push({
            x: item.keyword,
            y: item.count,
            // fillColor: "#5555FF",
          });
          relevance_avgs.push({
            x: item.keyword,
            y: item.relevance_avg,
            // fillColor: "#0000FF",
          });
        } else {
          counts.push({
            x: item.keyword,
            y: item.count,
            fillColor: "#FF5555",
          });
          relevance_avgs.push({
            x: item.keyword,
            y: item.relevance_avg,
            fillColor: "#FF0000",
          });
        }
      }
      this.chartMissingKeywordsOptions = {
        ...this.chartMissingKeywordsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesMissingKeywords = [
        { name: "count", data: counts },
        { name: "relevance_avg", data: relevance_avgs },
      ];
    },

    getChartMissingConceptsData(competitorsData, urlData) {
      const competitorsConceptsAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorConcepts = (({ concepts }) => ({ concepts }))(
          competitorData
        ).concepts;
        competitorConcepts.forEach((concept) => {
          competitorsConceptsAll.push(concept);
        });
      });
      const competitorsConceptsFiltered = [];
      competitorsConceptsAll.forEach((concept) => {
        const conceptData = {
          concept: concept.text,
          relevance_sum: concept.relevance,
          // rank_group_sum: concept.rank_group,
          count: 1,
          serps: [concept.url + ";" + concept.rank_group],
        };
        competitorsConceptsAll.forEach((concept2) => {
          if (concept.text == concept2.text && concept.url != concept2.url) {
            conceptData.relevance_sum += +concept2.relevance;
            conceptData.count++;
            conceptData.serps.push(concept2.url + ";" + concept2.rank_group);
          }
        });
        const data = {
          concept: conceptData.concept,
          count: conceptData.count,
          relevance_avg: Number(
            +conceptData.relevance_sum / +conceptData.count
          ).toFixed(3),
          serps: conceptData.serps,
        };
        competitorsConceptsFiltered.push(data);
      });
      const competitorsConcepts = competitorsConceptsFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["concept", "count", "relevance_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_avg < b.relevance_avg
            ? 1
            : b.relevance_avg < a.relevance_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const counts = [];
      const relevance_avgs = [];
      for (let i = 0; i < competitorsConcepts.slice(0, 20).length; i++) {
        const item = competitorsConcepts[i];
        chartOptions.push(item.concept);
        if (urlData.concepts.some((e) => e.concept === item.concept)) {
          const ownConceptData = urlData.concepts.filter((obj) => {
            return obj.concept === item.concept;
          })[0];
          counts.push({
            x: item.concept,
            y: item.count,
            fillColor: "#0000FF",
          });
          relevance_avgs.push({
            x: item.concept,
            y: -item.relevance_avg,
            fillColor: "#0000FF",
            // forecastDataPoints: {
            //   count: 0,
            //   fillOpacity: 0.5,
            //   strokeWidth: 20,
            //   dashArray: 4,
            // },

            // fillColor: "#EB8C87",
            // strokeColor: "#C23829",

            goals: [
              {
                name: "relevance",
                value: ownConceptData.relevance,
                strokeWidth: 5,
                strokeHeight: 10,
                strokeColor: "#00FF00",
              },
            ],
          });
        } else {
          counts.push({
            x: item.concept,
            y: item.count,
            fillColor: "#FF0000",
          });
          relevance_avgs.push({
            x: item.concept,
            y: -item.relevance_avg,
            fillColor: "#FF0000",
            // goals: [
            //   {
            //     name: "relevance to our url",
            //     value: 1,
            //     strokeWidth: 5,
            //     strokeHeight: 10,
            //     strokeColor: "#00FF00",
            //   },
            // ],
          });
        }
      }
      relevance_avgs.map((value) => {
        return (value.y = value.y * counts[0].y);
      });

      // this.chartMissingConceptsOptions.yaxis.min = -counts[0].y;
      // this.chartMissingConceptsOptions.yaxis.max = counts[0].y;
      this.chartMissingConceptsOptions = {
        ...this.chartMissingConceptsOptions,
        ...{
          yaxis: {
            min: -counts[0].y,
            max: counts[0].y,
            title: {
              text: "",
            },
          },
          xaxis: {
            categories: chartOptions,
            //  [
            //   "85+",
            //   "80-84",
            //   "75-79",
            //   "70-74",
            //   "65-69",
            //   "60-64",
            //   "55-59",
            //   "50-54",
            //   "45-49",
            //   "40-44",
            //   "35-39",
            //   "30-34",
            //   "25-29",
            //   "20-24",
            //   "15-19",
            //   "10-14",
            //   "5-9",
            //   "0-4",
            // ],
            title: {
              text: "",
            },
            labels: {
              show: true,
              // show: false,
              style: {
                // colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
              formatter: function (val) {
                if (val < 0) {
                  return Math.abs(Math.round(val) / counts[0].y).toFixed(1);
                }
                return Math.abs(Math.round(val)).toFixed(0);
              },
            },
          },

          dataLabels: {
            enabled: true,
            formatter: function (val) {
              if (val < 0) {
                return Math.abs(val / counts[0].y).toFixed(3);
              }
              return Math.abs(val);
            },
            style: {
              fontSize: "10px",
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ["Missing", "Existing"],
            markers: {
              fillColors: ["#FF0000", "#0000FF"],
            },
          },

          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val + "";
              },
            },
            y: {
              formatter: function (val) {
                if (val < 0) {
                  return Math.abs(val / counts[0].y).toFixed(3);
                }
                // return Math.abs(val).toFixed(0);
                return val;
              },
            },
          },
        },
      };

      this.seriesMissingConcepts = [
        // {
        //   name: "Males",
        //   data: [
        //     0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1,
        //     4.2, 4.5, 3.9, 3.5, 3,
        //   ],
        // },
        // {
        //   name: "Females",
        //   data: [
        //     -0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22,
        //     -4.3, -4.4, -4.1, -4, -4.1, -3.4, -3.1, -2.8,
        //   ],
        // },
        { name: "relevance_avg", data: relevance_avgs },
        // { name: "relevance_avg", data: relevance_avgsScaled },
        { name: "count", data: counts },
      ];
    },

    getChartConceptsDataCompetitors(concepts) {
      concepts.sort((a, b) =>
        a.relevance_avg < b.relevance_avg
          ? 1
          : b.relevance_avg < a.relevance_avg
          ? -1
          : 0
      );
      concepts.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      const chartOptions = [];
      const counts = [];
      for (let i = 0; i < concepts.slice(0, 20).length; i++) {
        const item = concepts[i];
        chartOptions.push(item.concept + "|" + item.relevance_avg);
        counts.push(item.count);
      }
      this.chartCompetitorsConceptsOptions = {
        ...this.chartCompetitorsConceptsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };

      this.seriesCompetitorsConcepts = [{ name: "count", data: counts }];
    },

    getChartCategoriesDataCompetitors(categories) {
      categories.sort((a, b) =>
        a.score_avg < b.score_avg ? 1 : b.score_avg < a.score_avg ? -1 : 0
      );
      categories.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      const chartOptions = [];
      const counts = [];
      for (let i = 0; i < categories.slice(0, 15).length; i++) {
        const item = categories[i];
        // chartOptions.push(item.label + "|" + item.score_avg);
        chartOptions.push(item.label);
        counts.push(item.count);
      }
      this.chartCompetitorsCategoriesOptions = {
        ...this.chartCompetitorsCategoriesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "7px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesCompetitorsCategories = [{ name: "count", data: counts }];
    },

    getChartEntitiesDataCompetitors(entities) {
      entities.sort((a, b) =>
        a.confidence_sum_avg < b.confidence_sum_avg
          ? 1
          : b.confidence_sum_avg < a.confidence_sum_avg
          ? -1
          : 0
      );
      entities.sort((a, b) =>
        a.relevance_sum_avg < b.relevance_sum_avg
          ? 1
          : b.relevance_sum_avg < a.relevance_sum_avg
          ? -1
          : 0
      );
      entities.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );

      const chartOptions = [];
      const counts = [];
      const count_sums = [];
      const count_sum_avgs = [];
      const relevance_sum_avgs = [];
      for (let i = 0; i < entities.slice(0, 20).length; i++) {
        const item = entities[i];
        chartOptions.push(item.text + "|" + item.type);
        counts.push(item.count);
        count_sums.push(item.count_sum);
        count_sum_avgs.push(item.count_sum_avg);
        relevance_sum_avgs.push(item.relevance_sum_avg);
      }
      this.chartCompetitorsEntitiesOptions = {
        ...this.chartCompetitorsEntitiesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesCompetitorsEntities = [
        { name: "count", data: counts },
        { name: "count_sum", data: count_sums },
        { name: "count_sum_avg", data: count_sum_avgs },
        { name: "relevance_sum_avgs", data: relevance_sum_avgs },
      ];
    },

    getChartKeywordsDataCompetitors(keywords) {
      keywords.sort((a, b) =>
        a.relevance_sum_avg < b.relevance_sum_avg
          ? 1
          : b.relevance_sum_avg < a.relevance_sum_avg
          ? -1
          : 0
      );
      keywords.sort((a, b) =>
        a.count_sums < b.count_sums ? 1 : b.count_sums < a.count_sums ? -1 : 0
      );
      keywords.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      const chartOptions = [];
      const counts = [];
      const count_sums = [];
      const count_sum_avgs = [];
      const relevance_sum_avgs = [];
      for (let i = 0; i < keywords.slice(0, 15).length; i++) {
        const item = keywords[i];
        chartOptions.push(
          // item.text + "|" + item.relevance_sum_avg + "|" + item.sentiment_label
          // item.text.slice(0, 10)
          item.text
          // item.text.split(" ").join("\n")
        );
        counts.push(item.count);
        count_sums.push(item.count_sum);
        count_sum_avgs.push(item.count_sum_avg);
        relevance_sum_avgs.push(item.relevance_sum_avg);
      }
      this.chartCompetitorsKeywordsOptions = {
        ...this.chartCompetitorsKeywordsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesCompetitorsKeywords = [
        { name: "count", data: counts },
        { name: "count_sum", data: count_sums },
        { name: "count_sum_avg", data: count_sum_avgs },
        { name: "relevance_sum_avg", data: relevance_sum_avgs },
      ];
    },

    getChartConceptsDataURL(concepts) {
      concepts.sort((a, b) =>
        a.relevance < b.relevance ? 1 : b.relevance < a.relevance ? -1 : 0
      );
      const chartOptions = [];
      const relevances = [];
      for (let i = 0; i < concepts.slice(0, 20).length; i++) {
        const item = concepts[i];
        chartOptions.push(item.concept);
        relevances.push(item.relevance);
      }
      this.chartURLConceptsOptions = {
        ...this.chartURLConceptsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };

      this.seriesURLConcepts = [{ name: "relevance", data: relevances }];
    },

    getChartCategoriesDataURL(categories) {
      categories.sort((a, b) =>
        a.score_avg < b.score_avg ? 1 : b.score_avg < a.score_avg ? -1 : 0
      );
      const chartOptions = [];
      const scores = [];
      for (let i = 0; i < categories.slice(0, 15).length; i++) {
        const item = categories[i];
        chartOptions.push(item.label);
        scores.push(item.score);
      }
      this.chartURLCategoriesOptions = {
        ...this.chartURLCategoriesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "7px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesURLCategories = [{ name: "score", data: scores }];
    },

    getChartEntitiesDataURL(entities) {
      entities.sort((a, b) =>
        a.relevance < b.relevance ? 1 : b.relevance < a.relevance ? -1 : 0
      );
      entities.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      entities.sort((a, b) =>
        a.confidence < b.confidence ? 1 : b.confidence < a.confidence ? -1 : 0
      );

      const chartOptions = [];
      const counts = [];
      const relevances = [];
      const confidences = [];
      for (let i = 0; i < entities.slice(0, 20).length; i++) {
        const item = entities[i];
        chartOptions.push(item.text + "|" + item.type);
        counts.push(item.count);
        relevances.push(item.relevance);
        confidences.push(item.confidence);
      }
      this.chartURLEntitiesOptions = {
        ...this.chartURLEntitiesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesURLEntities = [
        { name: "count", data: counts },
        { name: "relevance", data: relevances },
        { name: "confidence", data: confidences },
      ];
    },

    getChartKeywordsDataURL(keywords) {
      keywords.sort((a, b) =>
        a.relevance < b.relevance ? 1 : b.relevance < a.relevance ? -1 : 0
      );
      keywords.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      const chartOptions = [];
      const counts = [];
      const relevances = [];
      for (let i = 0; i < keywords.slice(0, 15).length; i++) {
        const item = keywords[i];
        chartOptions.push(item.text);
        counts.push(item.count);
        relevances.push(item.relevance);
      }
      this.chartURLKeywordsOptions = {
        ...this.chartURLKeywordsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesURLKeywords = [
        { name: "count", data: counts },
        { name: "relevance", data: relevances },
      ];
    },
  },
};
</script>